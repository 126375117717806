import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FeatureMenu from "../components/featureMenu"

const IndexPage = ({ data }) => {
  return (
    <Layout containerId="home">
      <Seo title="Mente" />
      <div className="hero" style={{ marginBottom: "50px" }}>
        <h1>Hva vil du bli bedre på?</h1>
      </div>
      <FeatureMenu />
    </Layout>
  )
}

export default IndexPage
